import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'


export const reportList = (inputSearch,dateType, paramBrand, paramAddress, paramLevel, startDate, endDate, page, pageSize ) => {
    return request({
        url: pvhApiBase + "Report/GetLoginStatics",
        method: "get",
        params: {
            "searchContent": inputSearch,
            "dateType": dateType,
            "paramBrand": paramBrand,
            "paramAddress": paramAddress,
            "paramLevel": paramLevel,
            "startDate": startDate,
            "endDate": endDate,
            "page": page,
            "pageSize": pageSize
        }
    })
}

export const ReportChartsPie = (dateType, paramBrand, paramAddress, paramLevel, startDate, endDate) => {
    return request({
        url: pvhApiBase + "Report/GetUserByLevel",
        method: "get",
        params: {
            "dateType": dateType,
            "paramBrand": paramBrand,
            "paramAddress": paramAddress,
            "paramLevel": paramLevel,
            "startDate": startDate,
            "endDate": endDate
        }

    })

}
export const ReportChartsPieTop10 = (dateType, paramBrand, paramAddress, paramLevel, startDate, endDate) => {
    return request({
        url: pvhApiBase + "Report/GetUserByLevelTop10",
        method: "get",
        params: {
            "dateType": dateType,
            "paramBrand": paramBrand,
            "paramAddress": paramAddress,
            "paramLevel": paramLevel,
            "startDate": startDate,
            "endDate": endDate
        }

    })

}

export const ReportChartsLine = (dateType, paramBrand, paramAddress, paramLevel, startDate, endDate) => {
    return request({
        url: pvhApiBase + "Report/GetUserByDate",
        method: "get",
        params: {
            "dateType": dateType,
            "paramBrand": paramBrand,
            "paramAddress": paramAddress,
            "paramLevel": paramLevel,
            "startDate": startDate,
            "endDate": endDate
        }
    })
}

export const AddressDropDownList = () => {
    return request({
        url: pvhApiBase + "Report/GetDropDowns",
        method: "get",
        params: {
         
        }
    })

}
export const UnifiedHelpReturnInfo = (remark, dateType, paramBrand, paramAddress, paramLevel, startDate, endDate) => {
    return request({
        url: pvhApiBase + "Report/GetUserSumsAsync",
        method: "get",
        params: {
            "remark": remark,
            "dateType": dateType,
            "paramBrand": paramBrand,
            "paramAddress": paramAddress,
            "paramLevel": paramLevel,
            "startDate": startDate,
            "endDate": endDate
       
        }
    })

}
export const exportExcel = () => request({
    url: businessUrl + "corpUsers/export",
    method: "get",
    responseType: "blob"
})