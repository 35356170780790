<template>
  <div id="home_container" class="pvhFactory2">
    <div id="pvhinfo_container">
      <el-container>
        <el-header class="headerDiv" style="padding:0; background-color: #E6E6E6">
          <el-row style="width: 100%;margin-top:10px">
            <el-col :xs="24" :sm="8" :md="8" :lg="4" :xl="4">
              <span>{{ $t('Brand') }}：</span>
              <el-select v-model="selBrandVal" filterable :placeholder="$t('Brand')" style="width: 70%;"
                         @change="brandselectChanged">
                <el-option v-for="item in brandData"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="8" :md="58" :lg="4" :xl="4">
              <span>{{ $t('Region') }}：</span>
              <el-select v-model="selAddressVal" filterable :placeholder="$t('Region')" style="width: 70%;"
                         @change="addressSelectChanged">
                <el-option v-for="item in addressData"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="4" :xl="4">
                            <span>
                                {{ $t('Level') }}：
                            </span>
              <el-select v-model="selLevelVal" filterable :placeholder="$t('Classification')" style="width: 70%;"
                         @change="levelSelectChanged">
                <el-option v-for="item in levelData"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3" style="padding-top:10px;">
              <el-radio v-model="radio" label="1" @change="changeRadioEvent">{{ $t('hour') }}</el-radio>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3" style="padding-top:10px;">
              <el-radio v-model="radio" label="2" @change="changeRadioEvent">{{ $t('week') }}</el-radio>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3" style="padding-top:10px;">
              <el-radio v-model="radio" label="3" @change="changeRadioEvent">{{ $t('month') }}</el-radio>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3" style="padding-top:10px;">
              <el-radio v-model="radio" label="4" @change="changeRadioEvent">{{ $t('year') }}</el-radio>
            </el-col>

          </el-row>
          <el-row style="width: 100%;">
            <el-col :xs="24" :sm="8" :md="8" :lg="4" :xl="4">
              <span>{{ $t('Brand') }}：</span>
              <el-input v-model="paramBrand" placeholder="" style="width:70%"></el-input>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="4" :xl="4">
              <span>{{ $t('Region') }}：</span>
              <el-input v-model="paramAddress" placeholder="" style="width:70%"></el-input>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="4" :xl="4">
              <span>{{ $t('Level') }}：</span>
              <el-input v-model="paramLevel" placeholder="" style="width:70%"></el-input>
            </el-col>
            <el-col :xs="6" :sm="5" :md="5" :lg="3" :xl="3" style="padding-top:10px;">
              <el-radio v-model="radio" label="5">{{ $t('zdyTime') }}</el-radio>
            </el-col>
            <el-col :xs="9" :sm="8" :md="8" :lg="3" :xl="3">
              <el-date-picker v-model="startDate"
                              type="date"
                              :placeholder="$t('startDate')" @change="changeStartTime" style="width:95%">
              </el-date-picker>
            </el-col>
            <el-col :xs="9" :sm="8" :md="8" :lg="3" :xl="3">
              <el-date-picker v-model="endDate"
                              type="date"
                              :placeholder="$t('endDate')" @change="changeEndTime" style="width:95%">
              </el-date-picker>
            </el-col>
            <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">

              <el-button type="primary" size="big" @click="searchFrom">{{ $t('SEARCH') }}</el-button>
            </el-col>

          </el-row>
          <el-row style="width: 100%;margin-top:15px">
            <el-col :span="1" style="text-align:left;">
            </el-col>
            <el-col :span="5" style="text-align:left;">
              <div
                  style="background-color: #15A2FF; display: flex; flex-direction: column; margin-right: 1rem; border-radius: 5px; ">
                <span
                    style="font-weight: 500; color: #fff; font-size: 14px; margin-left: 5px; ">{{
                    $t('userCounts')
                  }}</span>
                <div style="display:flex;flex-direction:row;">
                  <span
                      style="font-weight: 700; color: #fff; font-size: 18px; margin-right: 5px; margin-left: 5px;">{{
                      topInfoData.userCounts
                    }}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="3" style="text-align:left;">
            </el-col>
            <el-col :span="5" style="text-align:left;">
              <div
                  style="background-color: #15A2FF; display: flex; flex-direction: column; margin-right: 1rem; border-radius: 5px; ">
                <span
                    style="font-weight: 500; color: #fff; font-size: 14px; margin-left: 5px; padding-bottom: 3px;">{{
                    $t('loginUserCounts')
                  }}</span>
                <div style="display:flex;flex-direction:row;">
                  <span
                      style="font-weight: 700; color: #fff; font-size: 18px; margin-right: 5px; margin-left: 5px;">{{
                      topInfoData.loginUserCounts
                    }}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="3" style="text-align:left;">
            </el-col>
            <el-col :span="5" style="text-align:left;">
              <div
                  style="background-color: #15A2FF; display: flex; flex-direction: column; margin-right: 1rem; border-radius: 5px; ">
                <span
                    style="font-weight: 500; color: #fff; font-size: 14px; margin-left: 5px; padding-bottom: 3px;">{{
                    $t('userLoginCounts')
                  }}</span>
                <div style="display:flex;flex-direction:row;">
                  <span
                      style="font-weight: 700; color: #fff; font-size: 18px; margin-right: 5px; margin-left: 5px;">{{
                      topInfoData.userLoginCounts
                    }}</span>

                </div>
              </div>
            </el-col>
            <el-col :span="1" style="text-align:left;">
            </el-col>
          </el-row>
        </el-header>
        <el-container>
          <el-row style="width: 100%; background-color: #E6E6E6">
            <el-col :span="12" style="padding-right:20px;">
              <el-row style="width: 100%;">
                <el-col :span="24">
                  <div class="chartsDiv">
                    <div class="chartsHeaderDiv" style="display:flex;justify-content:space-between;align-items:center;">
                      <div style="text-align:left;width:100%;">
                        <img src="/img/button/angleMark.png" class="titImg" alt="">
                        <span class="tit" :title="$t('SUPPLIER CERTIFICATION QUANTITY')"
                              style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;vertical-align: middle;">{{
                            $t('PieTitleA')
                          }}</span>
                        <el-button class="btnDetail" icon="el-icon-s-order" type="text" :title="$t('Check the details')"
                                   @click="jupmSupplierCer()"></el-button>
                      </div>
                    </div>
                    <div class="chartsBodyDiv">
                      <div id="charts_01" style="width: 100%;height:198px;padding:10px 0 5px 0;"
                           v-loading="loading01"></div>
                    </div>
                  </div>
                </el-col>
              </el-row>

            </el-col>
            <el-col :span="12" style="padding-right: 20px;">
              <div class="chartsDiv">
                <div class="chartsHeaderDiv" style="display:flex;justify-content:space-between;align-items:center;">
                  <div style="text-align:left;">
                    <img src="/img/button/angleMark.png" class="titImg" alt="">
                    <span class="tit" :title="$t('TOTAL BRAND ANALYSIS')"
                          style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;vertical-align: middle;">{{
                        $t('OrderNums')
                      }}</span>
                    <el-button class="btnDetail" icon="el-icon-s-order" type="text" :title="$t('Check the details')"
                               @click="jupmPoDetail()"></el-button>
                  </div>

                </div>
                <div class="chartsBodyDiv">
                  <div id="charts_02" style="width: 100%;height:198px;padding:10px 0 5px 0;"
                       v-loading="loading02"></div>
                </div>
              </div>
            </el-col>

          </el-row>
          <el-row>

          </el-row>
        </el-container>
        <template>
          <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle"
                     :page.sync="page" @current-change="currentChange" @sort-change="sortChange"
                     :summary-method="summaryMethod">
            <template slot="menuLeft" style="width: calc(100% - 80px);">
              <el-row style="margin-top:20px;display: flex;align-items: center;">
                <el-col>
                  <div class="chartsHeaderDiv" style="display:flex;justify-content:space-between;align-items:center;">
                    <div style="text-align:left;width:100%; display: flex; align-items: center">
                      <img src="/img/button/angleMark.png" class="titImg" alt="">
                      <span class="tit" :title="$t('SUPPLIER CERTIFICATION QUANTITY')"
                            style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;display: inline-block;vertical-align: middle;">{{
                          $t('PurchaseDetailsList')
                        }}</span>
                      <el-button class="btnDetail" icon="el-icon-s-order" type="text" :title="$t('Check the details')"
                                 @click="jupmSupplierCer()"></el-button>
                    </div>
                    <div>
                      <el-input v-model="inputSearch" @blur="quickSearch" prefix-icon="el-icon-search"
                                :placeholder="$t('inputSearchCon')" style="width:250px"></el-input>
                    </div>
                  </div>
                </el-col>
                <el-col>
                  <el-button type="primary" size="big" @click="exportAll">{{ $t('Export') }}</el-button>
                </el-col>
              </el-row>
            </template>
          </avue-crud>
        </template>
      </el-container>
    </div>
  </div>
</template>


<style>

.btnDetail {
  font-size: 1rem !important;
  vertical-align: middle;
  padding-left: 3px;
}

.mapIpt {
  margin-right: 15px;
}

.mapIpt input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #fff;
  border-radius: 0;
}

#pvhinfo_container {
  padding: 0 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.chartsDiv {
  position: relative;
  border-radius: 0.625rem 0.625rem 0 0;
  height: 18.625rem !important;
  background-color: #fff;
}

.chartsHeaderDiv {
  width: 100%;
  display: flex;
  height: 15%;
  padding: 0 0.5rem;
  border-bottom: 1px solid #EBEBEB;
  box-sizing: border-box;
}

.chartsBodyDiv {
  width: 100%;
  display: flex;
  height: 85%;
  box-sizing: border-box;
}

.chartsMapDiv {
  position: relative;
  border-radius: 0.625rem 0.625rem 0 0;
  height: 460px !important;
  background-color: #fff;
}

.chartsHeaderMapDiv {
  width: 100%;
  display: flex;
  height: 7.5%;
  padding: 0 0.5rem;
  border-bottom: 1px solid #EBEBEB;
  box-sizing: border-box;
}

.chartsBodyMapDiv {
  width: 100%;
  display: flex;
  height: 90%;
  box-sizing: border-box;
}

.headerDiv {
  color: #333;
  text-align: center;
  height: auto !important;
}

.asideDiv {
  width: 33.3% !important;
  padding-left: 1.25rem;
  padding-right: 10px;
  color: #333;
  text-align: center;
}

.titImg {
  height: 1.3rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.el-col {
  margin-bottom: 1rem !important;
}

.tit {
  font-size: 0.75rem;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background: transparent;
}

.gm-style .gm-style-iw-c {
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
}

.gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg, rgba(0, 0, 0, .5) 50%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 0) 100%)
}

.pvhFactory2 .avue-crud .el-table th * {
  color: #000;
}

.pvhFactory2 .el-table__header-wrapper table {
  background: linear-gradient(to right, #E6E6E6, #E6E6E6);
  border-radius: 5px 5px 0 0;
}

.pvhFactory2 .el-table th, .pvhFactory2 .el-table th, .pvhFactory2 .el-table tr {
  background-color: transparent !important;
  padding: 15px 0;
}

.pvhFactory2 .el-table tr .el-table tr {
  background: rgba(204, 204, 204, .4) !important;
}
</style>

<script>
import {mapGetters, mapState} from "vuex";
import {listenfullscreen} from "@/util/util";
import {number} from "echarts";
import {
  reportList,
  ReportChartsPie,
  ReportChartsLine,
  AddressDropDownList,
  UnifiedHelpReturnInfo
} from "@/api/reportUserAccess";
import {ProcessDropDownList, TracingDropDownList} from "@/api/unified";
import {pvhApiBase} from '@/config/env';
import {getToken, getGrop} from '@/util/auth';

export default {
  data() {
    return {
      inputSearch: '',
      paramBrand: '',
      paramAddress: '',
      paramLevel: '',
      radio: '3',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: 'today',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: 'yesterday',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: 'aweekago',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      POd: "",
      restaurants: [],
      proCode: "",
      proName: "",
      loading02: true,
      loading01: true,
      selBrandVal: "",
      selLevelVal: "",
      selAddressVal: "",
      startDate: "",
      endDate: "",
      downUrl: '',
      dialogExportVisible: false,
      brandData: [],
      levelData: [],
      addressData: [],
      vendorData: [],
      // topInfoData: { numOrdNoCom: "0", ordersVar: "0", numOrdCom: "0" },
      topInfoData: {userCounts: "0", loginUserCounts: "0", userLoginCounts: "0"},
      obj: {},
      tableData: [],
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev,next",
        background: true,
        pageSize: 10
      }
    }
  },
  created() {
    this.getcerlist();
    this.init()
  },//加载
  mounted() {
    this.init();
    listenfullscreen(this.setS);
  },
  computed: {
    ...mapState({
      showDebug: state => state.common.showDebug,
      showColor: state => state.common.showColor,
      showTheme: state => state.common.showTheme,
      showLock: state => state.common.showLock,
      showFullScren: state => state.common.showFullScren,
      showCollapse: state => state.common.showCollapse,
      showSearch: state => state.common.showSearch,
      showMenu: state => state.common.showMenu
    }),
    ...mapGetters([
      "userInfo",
      "isFullScren",
      "tagWel",
      "tagList",
      "isCollapse",
      "tag",
      "logsLen",
      "logsFlag"
    ]),
    setData() {
      var validateExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id;
          if (this.obj.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("CertifiedNameExist", ids, value).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('NameIsExist')));
            } else {
              callback();
            }
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      };
      var validateCropExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id;
          if (this.obj.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("CertifiedCropExist", ids, value).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('CropIsExist')));
            } else {
              callback();
            }
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      };
      var validateCodeExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id;
          if (this.obj.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("CertifiedCodeExist", ids, value).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('CodeIsExist')));
            } else {
              callback();
            }
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      };
      //如果所属于最后一级可见下级可为空
      var validateRequired = (rule, value, callback) => {
        var VSections = this.obj.vendorSections.toString();
        value = value.toString();
        if (this.obj.vendorSections == undefined) {
          VSections = "";
        }
        TracingUnifiedIsBool("AllowedVendorSectionsRequired", VSections, value).then(resu => {
          var IsExist = resu.data;
          if (IsExist) {
            callback(new Error(this.$t('Required')));
          } else {
            callback();
          }
        }).catch(() => {
          callback();
        });
      };
      return {
        tableOpt: {
          page: true,
          refreshBtn: false,
          emptyText: this.$t('No Data'),
          menuTitle: ' ',
          menuWidth: 1,
          align: 'center',
          columnBtn: false,
          simplePage: false,
          addBtn: false,
          editBtn: false,
          delBtn: false,
          header: true,
          labelPosition: 'top',
          saveBtn: false,
          updateBtn: false,
          cancelBtn: false,
          defaultSort: {
            prop: 'weekponum',
            order: 'descending'
          },
          //index: true,
          //showSummary: true,
          //sumColumnList: [
          //    {
          //        name: 'userCount',
          //        type: 'sum'
          //    },
          //    {
          //        name: 'loginUserCount',
          //        type: 'sum'
          //    }
          //    ,
          //    {
          //        name: 'loginNumber',
          //        type: 'sum'
          //    }
          //    ,
          //    {
          //        name: 'visitNumber',
          //        type: 'sum'
          //    },
          //    {
          //        name: 'loginUserCountLast',
          //        type: 'sum'
          //    },
          //    {
          //        name: 'loginNumberLast',
          //        type: 'sum'
          //    }
          //    ,
          //    {
          //        name: 'visitNumberLast',
          //        type: 'sum'
          //    }
          //],
          column: [
            {
              label: this.$t('SupplierName'),
              prop: 'corp',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },
            {
              label: this.$t('UserQuantity'),
              prop: 'userCount',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },
            {
              label: this.$t('LoginuserQuantity'),
              prop: 'loginUserCount',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },
            {
              label: this.$t('LoginTimes'),
              prop: 'loginNumber',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },
            {
              label: this.$t('AccessMethods'),
              prop: 'visitNumber',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },
            {
              label: this.radio == 1 ? this.$t('hour') + this.$t('LoginuserQuantity') : this.radio == 2 ? this.$t('week') + this.$t('LoginuserQuantity') : this.radio == 3 ? this.$t('month') + this.$t('LoginuserQuantity') : this.$t('year') + this.$t('LoginuserQuantity'),
              prop: 'loginUserCountLast',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },
            {
              label: this.radio == 1 ? this.$t('hour') + this.$t('LoginTimes') : this.radio == 2 ? this.$t('week') + this.$t('LoginTimes') : this.radio == 3 ? this.$t('month') + this.$t('LoginTimes') : this.$t('year') + this.$t('LoginTimes'),
              prop: 'loginNumberLast',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },
            {
              label: this.radio == 1 ? this.$t('hour') + this.$t('AccessMethods') : this.radio == 2 ? this.$t('week') + this.$t('AccessMethods') : this.radio == 3 ? this.$t('month') + this.$t('AccessMethods') : this.$t('year') + this.$t('AccessMethods'),
              prop: 'visitNumberLast',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },


          ]
        }
      }
    }
  },
  methods: {
    init() {
      //品牌下拉框
      this.brandData = [];
      ProcessDropDownList('DictList', 'brand').then(res => {
        this.brandData = res.data;
        let newItem = {value: '', label: "ALL"};
        this.brandData.unshift(newItem);
      });
      //分类下拉框
      this.levelData = [];
      TracingDropDownList('VendorSectionList').then(res => {
        this.levelData = res.data;
        //this.selLevelVal = res.data[0].value;
        let newItem = {value: '', label: "ALL"};
        this.levelData.unshift(newItem);
      });
      //地区下拉框
      this.addressData = [];
      AddressDropDownList().then(res => {
        this.addressData = res.data;
        let newItem = {value: '', label: "ALL"};
        this.addressData.unshift(newItem);

      });
      this.loadData();
    },
    loadData() {
      //返回报表统计数据
      this.topInit();
      //加载图表
      this.loadChart01();
      this.loadChart02();
      //加载表格
      this.getcerlist();
    },
    //返回报表统计数据
    topInit() {

      UnifiedHelpReturnInfo('HomePage_chartDataTopInfo', this.radio, this.paramBrand, this.paramAddress, this.paramLevel, this.startDate, this.endDate).then(res => {
        var o = res.data;

        this.topInfoData.userCounts = o.Param1;
        this.topInfoData.loginUserCounts = o.Param2;
        this.topInfoData.userLoginCounts = o.Param3;

      })
    },
    //搜索
    searchFrom() {
      if (this.radio == 5) {
        if (this.startDate == null || this.startDate == "") {
          alert(this.$t('startTimeNotNull'));
          return false;
        }
        if (this.endDate == null || this.endDate == "") {
          alert(this.$t('endTimeNotNull'));
          return false;
        }
      }
      this.loadData();
    },//导出
    exportData() {

    },
    //时间选择事件
    changeRadioEvent(event) {
      this.loadData();
    },
    changeStartTime(v) {
    },

    changeEndTime(v) {

    },
    //品牌选择
    brandselectChanged(v) {
      this.paramBrand = v;
      this.loadData();
    },
    //级别选择
    levelSelectChanged(v) {
      this.paramLevel = v;
      this.loadData();
    },
    // 地区选择
    addressSelectChanged(v) {
      this.paramAddress = v;
      this.loadData();
    },
    quickSearch() {
      this.getcerlist();
    },
    //图2
    async loadChart02() {
      var chartDom = document.getElementById('charts_02');
      var myChart = this.$echarts.init(chartDom);
      var option;
      ReportChartsLine(this.radio, this.paramBrand, this.paramAddress, this.paramLevel, this.startDate, this.endDate).then(res => {
        var datainfo = res.data;

        if (datainfo != null) {
          option = {
            //调整空白左右上下间距
            grid: {
              left: '5%',
              right: '5%',
              top: "5%",
              bottom: '1%',
              containLabel: true
            },
            tooltip: {
              trigger: 'axis',
              textStyle: {
                align: 'left'
              }
            },
            xAxis: {
              type: 'category',
              data: datainfo.DT,
              boundaryGap: false,
              axisLabel: {'interval': 0, rotate: 35},
            },
            yAxis: {
              axisTick: {
                show: true
              },
              axisLine: {
                show: true
              },
              splitLine: {
                show: false
              },
              type: 'value',
            },
            color: ['#00B7FF'],
            series: [
              {
                data: datainfo.dataList,
                areaStyle: {},
                type: 'line',
                smooth: true,
                connectNulls: true,
                areaStyle: {
                  opacity: 0.8,
                  color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgba(160,219,255)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255)'
                    }
                  ])
                }
              }
            ]
          };
        }
        myChart.setOption(option);
        this.loading02 = false;
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    //图1
    async loadChart01() {

      var chartDom = document.getElementById('charts_01');
      var myChart = this.$echarts.init(chartDom);
      var option;
      ReportChartsPie(this.radio, this.paramBrand, this.paramAddress, this.paramLevel, this.startDate, this.endDate).then(res => {
        var datainfo = res.data;

        if (datainfo != null) {
          option = {
            //调整空白左右上下间距
            grid: {
              left: '1%',
              right: '3%',
              top: "5%",
              bottom: '5%',
              containLabel: true
            },
            tooltip: {
              //trigger: 'item',
              formatter: '{b0}: {c0}',
              textStyle: {
                fontSize: 8
              }
            },
            color: ['#3093FF', '#00B7FF', '#FFB820', '#72D1F8', '#15A2FF'],
            series: [
              {
                type: 'pie',
                radius: 80,
                center: ['50%', '50%'],
                data: datainfo,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                labelLine: {
                  normal: {
                    show: true
                  }
                },
                label: {
                  normal: {
                    formatter: '{b}:{c}' + '\n\r' + '({d}%)',
                    show: true,
                  },
                  fontSize: 8,
                },

              }
            ]
          };

        }
        this.loading01 = false;
        myChart.setOption(option);
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    //选择层级时，统计top10供应商
    async loadChart001() {

      var chartDom = document.getElementById('charts_01');
      var myChart = this.$echarts.init(chartDom);
      var option;
      ReportChartsPieTop10(this.radio, this.paramBrand, this.paramAddress, this.paramLevel, this.startDate, this.endDate).then(res => {
        var datainfo = res.data;

        if (datainfo != null) {
          option = {
            //调整空白左右上下间距
            grid: {
              left: '1%',
              right: '3%',
              top: "5%",
              bottom: '5%',
              containLabel: true
            },
            tooltip: {
              //trigger: 'item',
              formatter: '{b0}: {c0}',
              textStyle: {
                fontSize: 8
              }
            },
            color: ['#3093FF', '#00B7FF', '#FFB820', '#72D1F8', '#15A2FF'],
            series: [
              {
                type: 'pie',
                radius: 80,
                center: ['50%', '50%'],
                data: datainfo,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                labelLine: {
                  normal: {
                    show: true
                  }
                },
                label: {
                  normal: {
                    formatter: '{b}:{c}' + '\n\r' + '({d}%)',
                    show: true,
                  },
                  fontSize: 8,
                },

              }
            ]
          };

        }
        this.loading01 = false;
        myChart.setOption(option);
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    //显示数据
    async getcerlist() {
      await reportList(this.inputSearch, this.radio, this.paramBrand, this.paramAddress, this.paramLevel, this.startDate, this.endDate, this.page.currentPage, this.page.pageSize).then(res => {
        var cardata = res.data.userAccesss;

        this.tableData = cardata;
        this.page.total = res.data.totalCount;
        this.page.pageSize = res.data.pageSize;
      }).catch(erro => {
        console.log(erro);
      })
    },
    //行样式
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },//切换分页
    currentChange(val) {
      this.page.currentPage = val;
      this.getcerlist();
    },
    //此合计方法接受两个值,columns是列信息,data是行信息
    summaryMethod({columns, data}) {
      const sums = [];
      if (columns.length > 0) {
        columns.forEach((column, index) => {
          if ([0].includes(index)) {
            sums[index] = "合计";
          } else if (["SupplierName"].includes(column.property)) {   //add,是的prop,写在此数组中的列不会被合计
            //过滤某些字段不参与计算
            sums[index] = "-";
          } else {
            let values = data.map((item) => Number(item[column.property]));
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
            }
          }
        });
      }
      return sums;
    },
    //+    "&exportAll=" + ExportAllVal
    async getPurOrdListExcel(ExportAllVal) {
      this.downUrl = pvhApiBase + "Report/GetUserAccessExcel?page=" + this.page.currentPage + "&pageSize=" + this.page.pageSize + "&dateType=" + this.radio + "&paramBrand=" + this.paramBrand + "&paramAddress=" + this.paramAddress + "&paramLevel=" + this.paramLevel + "&startDate=" + this.startDate + "&endDate=" + this.endDate;

      let dt = new Date();
      var filename = dt.getFullYear() + '-' +
          ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
          (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
          (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
          (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
          (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
      this.downloadExcel(this.downUrl, "po" + filename);
    },
    exportAll() {
      this.dialogExportVisible = false;
      this.getPurOrdListExcel(true);
    },
    downloadExcel(url, fileName) {
      fetch(url, {
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'corpId': getGrop()
        }
      }).then(function (response) {
        if (response.ok) {
          return response.arrayBuffer()
        }
        throw new Error('Network response was not ok.')
      }).then(function (arraybuffer) {
        let blob = new Blob([arraybuffer], {
          type: `application/vnd.ms-excel`
        })
        let objectURL = URL.createObjectURL(blob)
        let downEle = document.createElement('a')
        let fname = fileName // 下载文件的名字
        // let fname = `download` // 下载文件的名字
        downEle.href = objectURL
        downEle.setAttribute('download', fname + '.xlsx')
        document.body.appendChild(downEle)
        downEle.click()
      }).catch(function (error) {
        console.log('There has been a problem with your fetch operation: ', error.message)
      })
    },

    //认证状态
    tracingPur(row) {
      this.$message({showClose: true, message: this.$t('online'), type: "warning"});
      // editStatus(row);
    },
    //全屏
    setS() {
      if (!this.isFullScren) {
        this.POd = "";
        this.proCode = "";
        this.proName = "";
        document.querySelector("#mapTopSear").style.display = "none";
        document.querySelector("#mapTwo").style.display = "none";
        document.querySelector("#mapScrTot").style.display = "none";
        document.querySelector("#mapTip").style.display = "none";
      } else {
        if (document.querySelector("#mapDiv").clientWidth === document.body.clientWidth) {
          document.querySelector("#mapTopSear").style.display = "block";
          document.querySelector("#mapTwo").style.display = "block";
          document.querySelector("#mapScrTot").style.display = "block";
          document.querySelector("#mapTip").style.display = "block";
        }
      }
    },
    //跳转详情页面
    jupmPoDetail() {
      this.$router.push({path: '/pvh_factory/purchaseOrd'})

    },
    jupmSupplierCer() {
      this.$router.push({path: '/pvh_setting/supplierCer'})
    },

  }
}

</script>